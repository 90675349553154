<template>
  <main class="main schools">
    <section class="container schools__container">
      <div class="schools__header">
        <h1 class="schools__title">Все школы</h1>
        <span class="schools__count">{{ schools_count }}</span>
      </div>
      <div class="schools__list" v-if="empty">
        <template v-if="loading">Загрузка...</template>
        <template v-else>Список пуст</template>
      </div>
      <div class="schools__list" v-else :class="{ 'schools__list--loading': loading }">
        <router-link
          :to="{ name: 'school', params: { link: school.link } }"
          v-for="(school, i) in schools.data"
          :key="i"
          class="schools-item"
        >
          <div class="schools-item__top">
            <img
              :alt="school.head_img | image_alt"
              class="schools-item__image"
              :src="school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
            />
            <div class="schools-item__rating" v-if="school.reviews_avg_mark">
              <span>{{ school.reviews_avg_mark.toFixed(1) }}</span>
              <StarIcon />
            </div>
          </div>
          <div class="schools-item__bottom">
            <h2 class="schools-item__title">
              {{ school.title }}
            </h2>
            <div class="schools-item__students">
              <b>{{ school.students_count }}</b>
              <span>Студентов</span>
            </div>
          </div>
        </router-link>
      </div>
      <PaginationComponent
        :page="page"
        v-if="!empty && schools.paginatorInfo.total > first"
        :total="Math.ceil(schools.paginatorInfo.total / first)"
        @change="paginate"
        @more="paginate($event, true)"
      />
    </section>
  </main>
</template>

<script>
import StarIcon from "components/svg/StarIcon.vue";
import PaginationComponent from "components/Pagination.vue";

const _first = 20;
const _page = 1;
export default {
  name: "SchoolsList",
  async asyncData({ apollo, store }) {
    await store.dispatch("SCHOOLS_PAGE", {
      apollo: apollo.defaultClient,
      loadingType: store.state._loading_types.DEFAULT,
      variables: {
        first: _first,
        page: _page,
      },
    });
  },
  data() {
    return {
      loading: false,
      first: _first,
      page: _page,
    };
  },
  computed: {
    empty() {
      return !(this.schools && this.schools.data && this.schools.data.length);
    },
    schools_count() {
      return this.$store.state.schools_page.schools_count;
    },
    schools() {
      return this.$store.state.schools_page.schools;
    },
  },
  methods: {
    async paginate(e, more = false) {
      if (!this.loading) {
        this.loading = true;
        this.page = e;
        let loadingType = this.$store.state._loading_types.PAGINATION;
        if (more) {
          loadingType = this.$store.state._loading_types.LOAD_MORE;
        }
        await this.$store.dispatch("SCHOOLS_PAGE", {
          apollo: this.$apollo,
          loadingType: loadingType,
          variables: {
            first: this.first,
            page: this.page,
          },
        });
        this.loading = false;
        window.scrollTo(0, 0);
      }
    },
  },
  components: { PaginationComponent, StarIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/textcut.styl"
.schools {
  padding 20px 32px 100px
  background var(--white)
  +below(640px) {
    padding 30px 20px 50px
  }

  &__container {
    display grid
    grid-gap 30px
  }

  &__header {
    display flex
    align-items center
    justify-content flex-start
    gap 15px
  }


  &__title {
    font-weight: bold;
    font-size: 3em;
    line-height: 58px;
    margin 0
    +below(1120px) {
      font-size: 2.75em
      line-height: normal;
    }
    +below(540px) {
      font-size: 1.875em
      line-height: 36px;
    }
  }

  &__count {
    background: var(--gray_light);
    padding 5px
    border-radius: 5px;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
  }

  &__list {
    width 100%
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 20px
    transition var(--transition)
    +below(1180px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(926px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(640px) {
      grid-template-columns 1fr
    }

    &--loading {
      opacity 0.1
    }
  }
}

.schools-item {
  display flex
  flex-direction column
  background: var(--white);
  border: 1px solid var(--border_color);
  box-sizing: border-box;
  border-radius: var(--radius);
  padding 25px
  gap 10px

  &:hover {
    color initial
    border-color var(--border_color)

    .icon svg path {
      fill var(--orange)
    }
  }

  &__bottom
  &__top {
    display flex
    align-items flex-start
    justify-content space-between
    gap 15px
  }

  &__image {
    width 50px
    height 50px
    object-fit contain
    object-position center;
    background: var(--gray_light);
    border-radius 100%
  }

  &__title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
    max-width 180px
    color: var(--black);
    margin 0
    textcut(3)
  }

  &__rating {
    display flex
    gap 5px
    justify-content flex-end
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
    color: var(--black);

    .icon {
      width 20px
      height 20px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__students {
    display flex
    flex-direction column
    align-items flex-end
    justify-content flex-end
    flex-shrink: 0;
    text-align: right;
    color: var(--black);

    b {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 22px;
    }

    span {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 17px;
    }
  }
}
</style>
